import { useEffect, useState } from "react";
import { IonLabel, IonCard, IonAvatar, IonItem } from "@ionic/react";
import { getAssetURL } from "../../utils/get-asset-url";

function CommentCard(props: any) {
  const [date, setDate] = useState("");
  useEffect(() => {
    const day = new Date(props.date_created).getDay();
    const month = new Date(props.date_created).getMonth();
    const year = new Date(props.date_created).getFullYear();
    setDate(`${day}/${month}/${year}`);
  }, [props]);
  return (
    <IonCard style={{maxWidth : "430px" , width : "90%", margin : " 25px auto", borderRadius: "20px"}} >
      <IonItem>
        <IonAvatar slot="start">
          <img
            alt="Silhouette of a person's head"
            src={getAssetURL(props.user_created?.avatar?.id) || 'https://ionicframework.com/docs/img/demos/avatar.svg'}
          />
        </IonAvatar>
        <IonLabel>
          <h3>
            {props.user_created?.first_name + " " + props.user_created?.last_name}
          </h3>
          <p style={{ fontSize: ".5rem" }}> Created: {date}</p>
        </IonLabel>
      </IonItem>
      <IonItem>
        <IonLabel class="ion-text-wrap">
          <p>Depth: {props.depth} meter</p>
          <p>Species of Corals: {props.species_of_corals_id.name}</p>
          <p>Bleaching Type: {props.bleaching_type}</p>
          <p>Comment: {props.comment}</p>
        </IonLabel>
      </IonItem>
    </IonCard>
  );
}
export default CommentCard;