// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "./SwiperCards.css";

import CustomSliderCard from "../cards/CustomSliderCard";

export default function SwiperCards(props) {
  const changePlaceMapHandle = (swiperCore) => {
    const { activeIndex } = swiperCore;
    props.funFly(props.cards[activeIndex].location?.coordinates);
  };

  return (
    <>
      <Swiper
        slidesPerView={1.2}
        centeredSlides={true}
        pagination={{
          clickable: true,
        }}
        className="mySwiper"
        onSlideChange={(swiperCore) => changePlaceMapHandle(swiperCore)}
      >
        {props.cards?.map((el) => (
          <SwiperSlide key={el.id} className="custom-slide">
            <CustomSliderCard {...el} {...props} />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}
