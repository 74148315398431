import { createContext } from "react";

export default createContext({
  isLogIn: false,
  updateComment: false,
  changeUpdateCommentHandle: (e: any) => {
    return e;
  },
  changeLogInHandle: (e: any) => {
    return e;
  },
});
