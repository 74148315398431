import { IonItem, IonLabel, IonInput } from "@ionic/react";

const CustomInput = (props: any) => {
  return (
    <div className="ion-padding-top">
      <IonLabel >
        <p>{ props.label || props.name} :</p>
      </IonLabel>
      <IonItem>
        <IonInput
          name={props.name}
          onIonChange={props.formik?.handleChange}
          value={props.formik?.values[props.name]}
          type={props.type || "text"} 
          color="dark"
        />
      </IonItem>
    </div>
  );
};

export default CustomInput;
