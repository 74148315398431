import { useState, useRef, useEffect } from "react";
import {
  IonButtons,
  IonButton,
  IonModal,
  IonHeader,
  IonThumbnail,
  IonContent,
  IonToolbar,
  IonTitle,
  IonLabel,
  IonItemOption,
  IonList,
  IonSearchbar,
} from "@ionic/react";
import "./SelectCoralsStyle.css";
import { useQuery } from "@apollo/client";
import { getCorals } from "../../utils/apolloQuery";
import { getAssetURL } from "../../utils/get-asset-url";
import { useFormik } from "formik";

const SelectCorals = (props: any) => {
  const modal = useRef<HTMLIonModalElement>(null);
  const page = useRef(undefined);

  const [searchVal, setSearchVal] = useState("");
  const [presentingElement, setPresentingElement] = useState<
    HTMLElement | undefined
  >(undefined);

  useEffect(() => {
    setPresentingElement(page.current);
  }, []);

  function dismiss() {
    modal.current?.dismiss();
    props.showHandle(false);
  }

  const selectItem = (id: string, name: String, image: String) => {
    props.handleChange(id, name, image);
    dismiss();
  };

  const { data, refetch } = useQuery(getCorals, {
    variables: {
      search: "",
    },
  });

  const formik = useFormik({
    initialValues: {
      search: "",
    },
    onSubmit: (values: any) => {
      values.search = searchVal;
      console.log(values);
      refetch(values);
    },
  });

  return (
    <IonModal
      ref={modal}
      isOpen={props.isOpen || false}
      presentingElement={presentingElement}
    >
      <IonHeader>
        <IonToolbar>
          <IonTitle>Chose type of corals</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => dismiss()}>Close</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonSearchbar
          value={searchVal}
          onIonChange={(e) => {
            const val = e.target.value || "";
            console.log(val);
            setSearchVal(val);
            formik.submitForm();
          }}
        />

        <IonList>
          {data?.species_of_corals.length === 0 ? (
            <p className="ion-text-center ion-padding" > there is no species of corals called {searchVal} </p>
          ) : (
            data?.species_of_corals?.map((el: any) => (
              <IonItemOption
                key={el.id}
                onClick={() => {
                  selectItem(el.id, el.name, el.image.id);
                }}
                color={el.id === props.selectedId ? "dark" : "light"}
                className="itemCustom ion-margin-vertical"
              >
                <div className="itemBox ion-padding-vertical">
                  <IonThumbnail>
                    <img
                      alt="Silhouette of mountains"
                      src={`${getAssetURL(el.image.id)} `}
                    />
                  </IonThumbnail>
                  <IonLabel className="ion-padding">{el.name}</IonLabel>
                </div>
              </IonItemOption>
            ))
          )}
        </IonList>
      </IonContent>
    </IonModal>
  );
};

export default SelectCorals;
