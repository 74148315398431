// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";

import CustomChips from "../Inputs/CustomChips";
import { useEffect, useState } from "react";

export default function ListCategory(props: any) {
  const [selectedCategory, setSelectedCategory] = useState<string[]>([]);

  useEffect(() => {
 
    
    props.handleChange(selectedCategory);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, selectedCategory]);

  useEffect(() => {
    if (selectedCategory.length === 0) {
      toggleSelectedCategoryHandle(props.btns[0]?.id)
      props.handleChange(selectedCategory);
    }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.btns]);

  const toggleSelectedCategoryHandle = (id: any) => {
    if (selectedCategory.includes(id)) {
      const newSelectedCategory = selectedCategory.filter((el) => el !== id);
      setSelectedCategory(newSelectedCategory);
    } else {
      setSelectedCategory([...selectedCategory, id]);
    }
  };

  return (
    <div className="ion-padding-vertical">
      <Swiper
        slidesPerView={"auto"}
        centeredSlides={false}
        pagination={{
          clickable: true,
        }}
      >
        {props.btns?.map((el: any) => (
          <SwiperSlide key={el.id}>
            <CustomChips
              {...el}
              selectcategoryFun={toggleSelectedCategoryHandle}
              selectedCategoryArr={selectedCategory}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
