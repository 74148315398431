import { useRef ,  } from "react";
import { IonModal, IonContent } from "@ionic/react";
import CommentCard from "../cards/CommentCard";
import AddCommentsModal from "./AddCommentsModal";

function CommentsModal(props: any) {
  const modal = useRef<HTMLIonModalElement>(null);

const closeComments = () => {
  props.openModel(false);
}
  return (
    <IonModal
      ref={modal}
      isOpen={props.isOpen}
      initialBreakpoint={0.25}
      breakpoints={[0, 0.25, 0.5, 0.75]}
      onWillDismiss={closeComments}
    >
      <IonContent>
        <AddCommentsModal closeComments={closeComments} placeId={props.placeId} />

        {props.comments?.length === 0 ? (
          <p className="ion-padding ion-text-center" > no comments available </p>
        ) : (
          props.comments?.map((el: any) => <CommentCard key={el.id} {...el} />)
        )}
      </IonContent>
    </IonModal>
  );
}

export default CommentsModal;
