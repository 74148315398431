import {
  ApolloClient,
  InMemoryCache,
  FetchResult,
  ApolloLink,
  gql,
  GraphQLRequest,
  HttpLink,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { Observable } from "@apollo/client/utilities";
import { GraphQLError } from "graphql";
import { api } from "./staticVariables";
import { RetryLink } from "@apollo/client/link/retry";

/**
 * Queries
 */

const REFRESH_TOKEN = gql`
  mutation Auth_refresh($refreshToken: String) {
    auth_refresh(refresh_token: $refreshToken) {
      access_token
      expires
      refresh_token
    }
  }
`;

/**
 * Apollo Setup
 */

function isRefreshRequest(operation: GraphQLRequest) {
  return operation.operationName === "refreshToken";
}

// Returns accesstoken if opoeration is not a refresh token request
function returnTokenDependingOnOperation(operation: GraphQLRequest) {
  if (isRefreshRequest(operation))
    return localStorage.getItem("refreshToken") || "";
  else return localStorage.getItem("accessToken") || "";
}

const mainLink = new HttpLink({
  uri: api,
});

const systemLink = new HttpLink({
  uri: api + "/system",
});

const authLink = setContext((operation, { headers }) => {
  let token = returnTokenDependingOnOperation(operation);

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const directionalLink = new RetryLink().split(
  (operation: any) => operation.getContext().clientName === "auth",
  systemLink,
  mainLink
);

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
    //   console.log("FFF", graphQLErrors);
      for (let err of graphQLErrors) {
        switch (err?.extensions?.code) {
          case "TOKEN_EXPIRED":
            // ignore 401 error for a refresh request
            if (operation.operationName === "refreshToken") return;

            const observable = new Observable<FetchResult<Record<string, any>>>(
              (observer) => {
                // used an annonymous function for using an async function
                (async () => {
                  try {
                    console.log("Trying to get new token");

                    const accessToken = await refreshToken();

                    if (!accessToken) {
                      throw new GraphQLError("Empty AccessToken");
                    }

                    // Retry the failed request
                    const subscriber = {
                      next: observer.next.bind(observer),
                      error: observer.error.bind(observer),
                      complete: observer.complete.bind(observer),
                    };

                    forward(operation).subscribe(subscriber);
                  } catch (err) {
                    observer.error(err);
                  }
                })();
              }
            );

            return observable;
        }
      }
    }

    if (networkError) console.log(`[Network error]: ${networkError}`);
  }
);

const client = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, directionalLink]),
  cache: new InMemoryCache(),
});
export default client;

const clientAuth = new ApolloClient({
  link: ApolloLink.from([errorLink, systemLink]),
  cache: new InMemoryCache(),
});

// Request a refresh token to then stores and returns the accessToken.
const refreshToken = async () => {
  try {
    console.log("refreshTokenLocalStorage: ", localStorage.refreshToken);
    const refreshResolverResponse = await clientAuth.mutate({
      mutation: REFRESH_TOKEN,
      variables: { refreshToken: localStorage.refreshToken },
    });
    console.log("ref1", refreshResolverResponse);
    const accessToken =
      refreshResolverResponse.data?.auth_refresh?.access_token;
    const refreshToken =
      refreshResolverResponse.data?.auth_refresh?.refresh_token;
    localStorage.setItem("accessToken", accessToken || "");
    localStorage.setItem("refreshToken", refreshToken || "");
    return accessToken;
  } catch (err) {
    console.log("err from refreshToken", err);
    localStorage.clear();
    throw err;
  }
};
