import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import SignUpForm from "../components/auth/SignUpForm";
import PageHeader from "../components/cards/PageHeader";

const SignUp: React.FC = () => {
  return (
    <IonPage>
      <PageHeader title="Sign up" />

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Log in</IonTitle>
          </IonToolbar>
        </IonHeader>
        <SignUpForm />
      </IonContent>
    </IonPage>
  );
};

export default SignUp;
