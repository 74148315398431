import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import MapLib from "../components/map/MapLib";
import { useQuery } from "@apollo/client";
import { getPlace } from "../utils/apolloQuery";
import PageHeader from "../components/cards/PageHeader";
import CustomFab from "../components/Inputs/CustomFab";

const Map: React.FC = () => {
  const { data } = useQuery(getPlace);

  return (
    <IonPage>
      <PageHeader title="Red Sea Conserve" />
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Red Sea Conserve</IonTitle>
          </IonToolbar>
        </IonHeader>
        <MapLib {...data} />
        <CustomFab />
      </IonContent>
    </IonPage>
  );
};

export default Map;
