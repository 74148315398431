import CustomInput from "../Inputs/CustomInput";
import { IonButton } from "@ionic/react";
import { useFormik } from "formik";
import { useMutation } from "@apollo/client";
import { signUpAuthQuery } from "../../utils/apolloQuery";
import { IonLabel, useIonToast } from "@ionic/react";
import { useHistory } from "react-router-dom";

const SignUpForm: React.FC = () => {
  const [SignUp, { loading }] = useMutation(signUpAuthQuery);
  const [present] = useIonToast();
  let history = useHistory();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const presentToast = (text: string, type: string) => {
    present({
      message: text,
      duration: 1500,
      position: "bottom",
      color: type,
      cssClass: "ZIndexToast",
    });
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      first_name: "",
      last_name: "",
      password: "",
    },
    onSubmit: (values: any) => {
      SignUp({
        variables: {
          data: values,
        },
        context: { clientName: "auth" },
        onCompleted: (res) => {
          presentToast(
            "account created successfully now you can log in ",
            "success"
          );

          formik.resetForm();
          history.push("/log-in");
        },
        onError: (err) => {
          presentToast(err.message, "danger");
        },
      });
    },
  });

  return (
    <div className="ion-padding">
      <form onSubmit={formik.handleSubmit}>
        <CustomInput formik={formik} label="First Name" name="first_name" />
        <CustomInput formik={formik} label="Last Name" name="last_name" />
        <CustomInput formik={formik} label="Email" name="email" />
        <CustomInput
          formik={formik}
          type="password"
          label="Password"
          name="password"
        />
        <IonButton
          className="ion-margin-vertical"
          color="dark"
          expand="block"
          type="submit"
          disabled={loading}
        >
          Sign up
        </IonButton>
      </form>

      <IonLabel
        className="ion-padding-top ion-justify-content-center ion-align-items-center"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <p>Already have an account ?</p>
        <IonButton
          routerLink="/log-in"
          fill="clear"
          className="ion-margin-vertical"
          color="dark"
        >
          Log in
        </IonButton>
      </IonLabel>
    </div>
  );
};

export default SignUpForm;
