import {
  IonItem,
  IonList,
  IonSelect,
  IonSelectOption,
  IonLabel,
} from "@ionic/react";
function CustomSelector(props: any) {
  return (
    <IonList className="ion-padding-top">
      <IonLabel>
        <p>{props.label} : </p>
      </IonLabel>
      <IonItem>
        <IonSelect
          value={props.val}
          name={props.name}
          color="dark"
          onIonChange={(e) => {
            props.handleChange(e.target.value);
          }}
        >
          <IonLabel>{props.label}</IonLabel>
          {props.items?.map((el: any) => (
            <IonSelectOption key={el.id} value={el.id} color="dark">
              {el.name}
            </IonSelectOption>
          ))}
        </IonSelect>
      </IonItem>
    </IonList>
  );
}
export default CustomSelector;
