import { IonFab, IonFabButton, IonIcon , useIonToast} from "@ionic/react";
import { add } from "ionicons/icons";
import { useContext } from "react";
import authContext from "../../context/authContext";
import { useHistory } from "react-router-dom";

const CustomFab = () => {
  const myAuthContext = useContext(authContext);
  const [present] = useIonToast();
  let history = useHistory();

  const openHandle = () => {
    if (myAuthContext.isLogIn) {
      history.push("/add-place")
    } else {
    
      present({
        message: "you must Log in first",
        duration: 1500,
        position: "bottom",
        color :"danger",
      });
      history.push("/log-in")
    }
  };


  return (
    <IonFab vertical="center"horizontal="start" slot="fixed">
      <IonFabButton  size="small" onClick={openHandle} color="dark" >
        <IonIcon icon={add} />
      </IonFabButton>
    </IonFab>
  );
};

export default CustomFab