import { gql } from "@apollo/client";

export const getCategory = gql`
  query Category {
    category {
      id
      name
      icon
    }
  }
`;

export const getPlace = gql`
  query Place {
    place {
      id
      name
      user_created {
        id
        first_name
        last_name
        avatar {
          id
        }
      }
      date_created
      location
      r_levelone_id {
        name
      }
      main_image {
        id
      }
    }
  }
`;

export const getComments = gql`
  query Comments($filter: comments_filter) {
    comments(filter: $filter) {
      id
      comment
      status
      depth
      species_of_corals_id {
        name
      }
      bleaching_type
      user_created {
        id
        first_name
        last_name
        avatar {
          id
        }
      }
      date_created
      image {
        id
      }
    }
  }
`;
export const addComments = gql`
  mutation Mutation($data: create_comments_input!) {
    create_comments_item(data: $data) {
      id
      comment
    }
  }
`;

export const getCorals = gql`
query Species_of_corals($search: String) {
  species_of_corals(search: $search) {
    name
    id
    image(search: $search) {
      id
      filename_disk
    }
  }
}
`;

export function commentsFilter(placeID: String) {
  return {
    filter: {
      place_id: {
        id: {
          _eq: placeID,
        },
      },
    },
  };
}

export const logInAuthQuery = gql`
  mutation Auth_login($email: String!, $password: String!) {
    auth_login(email: $email, password: $password) {
      access_token
      refresh_token
    }
  }
`;

export const signUpAuthQuery = gql`
  mutation Create_users_items($data: [create_directus_users_input!]) {
    create_users_items(data: $data) {
      first_name
      last_name
    }
  }
`;


export const addPlaceQuery = gql`
mutation Mutation($data: create_place_input!) {
  create_place_item(data: $data) {
    id
    name
  }
}
`

export const getRegionQuery = gql`
query R_levelone {
  r_levelone {
    id
    name
  }
}
`