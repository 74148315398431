import CustomInput from "../Inputs/CustomInput";
import { IonButton } from "@ionic/react";
import { useFormik } from "formik";
import { useMutation } from "@apollo/client";
import { logInAuthQuery } from "../../utils/apolloQuery";
import { IonLabel, useIonToast } from "@ionic/react";
import { useHistory } from "react-router-dom";
import { useContext } from "react";
import "./auth.style.css"
import authContext from "../../context/authContext";

const LogInForm = (props: any) => {
  const [logInHandle, { loading }] = useMutation(logInAuthQuery);
  const [present] = useIonToast();
  let history = useHistory();
  const myAuthContext = useContext(authContext);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const presentToast = (text: string , type:string) => {
    present({
      message: text,
      duration: 1500,
      position: "bottom",
      color: type,
      cssClass: 'ZIndexToast'
    });
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: (values: any) => {
      logInHandle({
        variables: values,
        context: { clientName: "auth" },
        onCompleted: (res) => {
         
          presentToast("you logged in successfully " , "success");
          localStorage.setItem("accessToken", res.auth_login?.access_token);
          localStorage.setItem("refreshToken", res.auth_login?.refresh_token);
          myAuthContext.changeLogInHandle(true);
          formik.resetForm();
          history.push("/");

        },
        onError: (err) => {
          presentToast(err.message , "danger");
        },
      });
    },
  });

  return (
    <div className="ion-padding">
      <form onSubmit={formik.handleSubmit}>
        <CustomInput formik={formik} label="Email" name="email" />
        <CustomInput
          formik={formik}
          type="password"
          label="Password"
          name="password"
        />
        <IonButton
          disabled={loading}
          color="dark"
          className="ion-margin-vertical"
          expand="block"
          type="submit"
        >
          Log in
        </IonButton>
      </form>

      <IonLabel
        className="ion-padding ion-justify-content-center ion-align-items-center"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <p>Don't have an account ?</p>
        <IonButton
          routerLink="/sign-up"
          fill="clear"
          className="ion-margin-vertical"
          color="dark"
        >
          Sign up
        </IonButton>
      </IonLabel>
    </div>
  );
};

export default LogInForm;
