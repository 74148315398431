import { useRef, useState } from "react";
import {
  IonButtons,
  IonButton,
  IonModal,
  IonHeader,
  IonContent,
  IonToolbar,
  IonTitle,
  IonLabel,
  IonItem,
  useIonToast,
  IonItemOption,
  IonThumbnail,
} from "@ionic/react";
import CustomInput from "../Inputs/CustomInput";
import CustomTextarea from "../Inputs/CustomTextarea";
import CustomRadio from "../Inputs/CustomRadio";
import { useFormik } from "formik";
import { useMutation } from "@apollo/client";
import { addComments } from "../../utils/apolloQuery";
import SelectCorals from "./SelectCorals";
import { getAssetURL } from "../../utils/get-asset-url";
import { useContext } from "react";
import authContext from "../../context/authContext";
import { useHistory } from "react-router-dom";

function AddCommentsModal(props: any) {
  const modal = useRef<HTMLIonModalElement>(null);
  const [radioVal, setRadioVal] = useState("old");
  const [coralsVal, setCoralsVal] = useState("");
  const [coralsName, setCoralsName] = useState("coralsName");
  const [coralsImage, setCoralsImage] = useState("");
  const [isOpen, setOpen] = useState(false);
  const [isOpenCoral, setIsOpenCoral] = useState(false);

  const myAuthContext = useContext(authContext);
 const history = useHistory()
  const [present] = useIonToast();

  const handelChangeRadio = (val: string) => {
    setRadioVal(val);
  };
  const handelCoralsChange = (val: string, name: string, image: string) => {
    setCoralsVal(val);
    setCoralsName(name);
    setCoralsImage(image);
  };
  const showOption = (toggle: boolean) => {
    setIsOpenCoral(toggle);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const presentToast = (text: string, type: string) => {
    present({
      message: text,
      duration: 1500,
      position: "bottom",
      color: type,
    });
  };

  const [addComment, { loading }] = useMutation(addComments);

  const formik = useFormik({
    initialValues: {
      comment: "",
      depth: 0,
      species_of_corals_id: "",
      bleaching_type: "old",
      place_id: { id: "" },
    },
    onSubmit: (values: any) => {
      values.bleaching_type = radioVal;
      values.species_of_corals_id = coralsVal;
      values.place_id = { id: props.placeId };
      addComment({
        variables: { data: values },
        onCompleted: (res) => {
          presentToast("Comment add successfully", "success");
          formik.resetForm();
          modal.current?.dismiss();
          myAuthContext.changeUpdateCommentHandle(true)
        },
        onError: (err) => {
          presentToast("Some thing want Wrong", "danger");
        },
      });
    },
  });

  function confirm() {
    formik.handleSubmit();
  }

  const openHandle = () => {
    if (myAuthContext.isLogIn) {
      setOpen(true);
    } else {
      presentToast("you must Log in first", "danger");
      props.closeComments()
      history.push("/log-in")
    }
  };

  return (
    <>
      <div className="ion-padding-start ion-padding-top">
        <IonButton fill="outline" color="dark" onClick={openHandle}>
          add comment
        </IonButton>
      </div>
      <IonModal ref={modal} isOpen={isOpen}>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton
                onClick={() => {
                  modal.current?.dismiss();
                  setOpen(false);
                }}
              >
                Cancel
              </IonButton>
            </IonButtons>

            <IonButtons slot="end">
              <IonButton
                strong={true}
                disabled={loading}
                type="submit"
                onClick={() => confirm()}
              >
                Confirm
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <IonTitle style={{ textAlign: "center" }}>add new comment</IonTitle>
          <CustomInput
            type="number"
            name="depth"
            formik={formik}
            label="depth (meter)"
          />
          <CustomTextarea name="comment" formik={formik} />

          <div className="ion-padding-top">
            <IonLabel>
              <p>species of corals </p>
            </IonLabel>
            <IonItem>
              {coralsVal ? (
                <IonItemOption
                  key={coralsVal}
                  onClick={() => showOption(true)}
                  color="dark"
                  className="itemCustom ion-margin-vertical"
                >
                  <div className="itemBox ion-padding-vertical">
                    <IonThumbnail>
                      <img alt="" src={`${getAssetURL(coralsImage)} `} />
                    </IonThumbnail>
                    <IonLabel className="ion-padding">{coralsName}</IonLabel>
                  </div>
                </IonItemOption>
              ) : (
                <IonButton
                  expand="block"
                  color="dark"
                  onClick={() => showOption(true)}
                  fill="clear"
                  style={{ width: "100%", textTransform: "capitalize" }}
                >
                  select species of corals
                </IonButton>
              )}
            </IonItem>
          </div>
          <CustomRadio
            name="bleaching_type"
            label="bleaching type"
            val={radioVal}
            handleChange={handelChangeRadio}
          />
          <SelectCorals
            isOpen={isOpenCoral}
            showHandle={showOption}
            handleChange={handelCoralsChange}
            selectedId={coralsVal}
          />
        </IonContent>
      </IonModal>
    </>
  );
}

export default AddCommentsModal;
