import {
  IonItem,
  IonLabel,
  IonList,
  IonRadio,
  IonRadioGroup,
  IonCol,
  IonRow,
  IonGrid,
} from "@ionic/react";

function CustomRadio(props: any) {
  return (
    <IonList className="ion-padding-top">
      <IonRadioGroup
        value={props.val}
        name={props.name}
        onIonChange={(e) => {
          props.handleChange(e.target.value);
        }}
      >
        <IonLabel>
          <p>{props.label} :</p>
        </IonLabel>

        <IonGrid>
          <IonRow>
            <IonCol size="6">
              <IonItem>
                <p>old</p>
                <IonRadio
                  slot="end"
                  value="old"
                  color="dark"

                ></IonRadio>
              </IonItem>
            </IonCol>
            <IonCol size="6">
              <IonItem>
                <p>new</p>
                <IonRadio
                  slot="end"
                  value="new"
                ></IonRadio>
              </IonItem>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonRadioGroup>
    </IonList>
  );
}
export default CustomRadio;
