import {
  IonHeader,
  IonTitle,
  IonButtons,
  IonToolbar,
  IonButton,
  IonIcon,
  IonBackButton,
  useIonAlert,
} from "@ionic/react";
import { personOutline, logOutOutline } from "ionicons/icons";
import { useEffect, useContext } from "react";
import authContext from "../../context/authContext";
import { useHistory } from "react-router";
const PageHeader = (props: any) => {
  const myAuthContext = useContext(authContext);
  const [presentAlert] = useIonAlert();
  const history = useHistory()

  useEffect(() => {
    logInCheckHandle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myAuthContext]);

  useEffect(() => {}, [props.title]);

  const logInCheckHandle = () => {
    if (localStorage.accessToken) {
      myAuthContext.changeLogInHandle(true);
    }
  };

  const LogOutHandle = () => {
    presentAlert({
      header: "Are you sure you want to log out ?",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
        },
        {
          text: "yes",
          role: "confirm",
          handler: () => {
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");
            myAuthContext.changeLogInHandle(false);
            history.push("/")
          },
        },
      ],
    });
  };

  return (
    <IonHeader>
      <IonToolbar>
        {props.title === "Red Sea Conserve" ? null : (
          <IonButtons
            onClick={() => {
              logInCheckHandle();
            }}
            slot="start"
          >
            <IonBackButton defaultHref="/" />
          </IonButtons>
        )}
        <IonButtons slot="secondary">
          {myAuthContext.isLogIn ? (
            <IonButton onClick={LogOutHandle}>
              <IonIcon slot="icon-only" icon={logOutOutline} />
            </IonButton>
          ) : (
            <IonButton routerLink="/log-in">
              <IonIcon slot="icon-only" icon={personOutline} />
            </IonButton>
          )}
        </IonButtons>
        <IonTitle>{props.title}</IonTitle>
      </IonToolbar>
    </IonHeader>
  );
};

export default PageHeader;
