import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import LogInForm from "../components/auth/LogInForm";
import PageHeader from "../components/cards/PageHeader";
import { useState } from "react";
const LogIn: React.FC = () => {
 
  return (
    <IonPage>
      <PageHeader title="Log In" />

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Log in</IonTitle>
          </IonToolbar>
        </IonHeader>
        <LogInForm  />
      </IonContent>
    </IonPage>
  );
};

export default LogIn;
