import { IonItem, IonLabel, IonTextarea } from "@ionic/react";

const CustomTextarea = (props: any) => {
  return (
    <div className="ion-padding-top">
      <IonLabel>
        <p>{props.name} : </p>
      </IonLabel>
      <IonItem>
        <IonTextarea
          autoGrow={true}
          name={props.name}
          onIonChange={props.formik?.handleChange}
          value={props.formik?.values[props.name]}
          rows={1}
          color="dark"
        />
      </IonItem>
    </div>
  );
};

export default CustomTextarea;
