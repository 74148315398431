export const api = "https://betterexp.georeportit.me/graphql";

export const centerPoint = [37.1061267471479, 22.580573427888993];

export const layersMapArr = [
    {
      name: "Bleaching Alert Area",
      id: "bleaching-alert-area",
      link: "https://storage.googleapis.com/production-coral-tiles/crw/baa/latest/{z}/{x}/{y}.png",
      legend: "https://coralreefwatch.noaa.gov/product/vs/legends/trans_border_baa_gm_legend_sm_v2.png"
    },
    {
      name: "Bleaching Hot Spot",
      id: "bleaching-hot-spot",
      link: "https://storage.googleapis.com/production-coral-tiles/crw/hs/latest/{z}/{x}/{y}.png",
      legend: "https://coralreefwatch.noaa.gov/product/vs/legends/trans_border_hs_gm_legend_sm_v2.png"
    },
    {
      name: "Degree Heating Week",
      id: "degree-heating-week",
      link: "https://storage.googleapis.com/production-coral-tiles/crw/dhw/latest/{z}/{x}/{y}.png",
      legend: "https://coralreefwatch.noaa.gov/product/vs/legends/trans_border_dhw20_gm_legend_sm_v2.png"
    },
    {
      name: "Sea Surface Temperature Anomaly",
      id: "sea-surface-temperature-anomaly",
      link: "https://storage.googleapis.com/production-coral-tiles/crw/ssta/latest/{z}/{x}/{y}.png",
      legend: "https://coralreefwatch.noaa.gov/product/vs/legends/trans_border_ssta_gm_legend_sm_v2.png"
    },
    {
      name: "Sea Surface Temperature Trend",
      id: "sea-surface-temperature-trend",
      link: "https://storage.googleapis.com/production-coral-tiles/crw/sstt/latest/{z}/{x}/{y}.png",
      legend: "https://coralreefwatch.noaa.gov/product/vs/legends/trans_border_sst_trend_gm_legend_sm_v2.png"
    },
    {
      name: "Sea Surface Temperature",
      id: "sea-surface-temperature",
      link: "https://storage.googleapis.com/production-coral-tiles/crw/sst/latest/{z}/{x}/{y}.png",
      legend: "https://coralreefwatch.noaa.gov/product/vs/legends/trans_border_sst_gm_legend_sm_v2.png"
    }
  ];