import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonLabel,
  IonButton,
  useIonToast,
} from "@ionic/react";
import PageHeader from "../components/cards/PageHeader";
import CustomInput from "../components/Inputs/CustomInput";
import AddPlaceMap from "../components/map/AddPlaceMap";
import { useFormik } from "formik";
import { useMutation } from "@apollo/client";
import { addPlaceQuery } from "../utils/apolloQuery";
import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { getRegionQuery } from "../utils/apolloQuery";
import { centerPoint } from "../utils/staticVariables";
import CustomSelector from "../components/Inputs/CustomSelector";
import { useHistory } from "react-router";

const AddPlace: React.FC = () => {
  const [present] = useIonToast();
  const [coordinates, setCoordinates] = useState([0, 0]);
  const [regionVal, setRegionVal] = useState("");

  const { data } = useQuery(getRegionQuery);
  let history = useHistory();
  const updateRegionVal = (val: string) => {
    setRegionVal(val);
  };

  const updateCoordinates = (coordinates: any) => {
    setCoordinates(coordinates);
  };

  useEffect(() => {
    updateRegionVal(data?.r_levelone[0]?.id);
  }, [data]);

  const presentToast = (text: string, type: string) => {
    present({
      message: text,
      duration: 1500,
      position: "bottom",
      color: type,
    });
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      r_levelone_id: "",
      location: {
        type: "Point",
        coordinates: centerPoint,
      },
    },
    onSubmit: (values) => {
      values.location.coordinates = coordinates;
      values.r_levelone_id = regionVal;
      console.log(values);
      addNewPlace({
        variables: { data: values },

        onCompleted: (res) => {
          presentToast(
            `you add ${res.create_place_item.name} successfully`,
            "success"
          );
          history.push("/");
          formik.resetForm();
        },
        onError: (err) => {
          presentToast(err.message, "danger");
        },
      });
    },
  });

  const [addNewPlace, { loading }] = useMutation(addPlaceQuery);

  return (
    <IonPage>
      <PageHeader title="Add new Place" />

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Add new Place</IonTitle>
          </IonToolbar>
        </IonHeader>
        <form onSubmit={formik.handleSubmit} className="ion-padding">
          <CustomInput name="name" formik={formik} />

          <CustomSelector
            handleChange={updateRegionVal}
            val={regionVal}
            items={data?.r_levelone}
            label="chose region "
          />

          <IonLabel>
            <p className="ion-padding-vertical">
              put the pin into point at location of place :
            </p>
          </IonLabel>
          <div style={{ height: "290px " }}>
            <AddPlaceMap updateCoordinates={updateCoordinates} />
          </div>
          <IonButton
            className="ion-margin-vertical"
            expand="block"
            type="submit"
            color="dark"
            disabled={loading}
          >
            Confirm
          </IonButton>
        </form>
      </IonContent>
    </IonPage>
  );
};

export default AddPlace;
