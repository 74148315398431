import maplibregl from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";
import { useEffect, useRef } from "react";
import {centerPoint} from "../../utils/staticVariables"

const AddPlaceMap = (props) => {

  const mapContainerRef = useRef();

  useEffect(() => {
    const map = new maplibregl.Map({
      container: mapContainerRef.current,
      style: `https://api.maptiler.com/maps/streets/style.json?key=jPG7PthF5c3gGwBLewgX`,
      center: centerPoint,
      zoom: 4,
      attributionControl: false,
    });

    map.addControl(
      new maplibregl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        trackUserLocation: true,
        showUserHeading: true,
      }),
      "bottom-left"
    );

    // marker
    const Marker = new maplibregl.Marker({ color: "#FF0000" })
      .setLngLat(centerPoint)
      .addTo(map);

    // events
    map.on("drag", (e) => {
      Marker.setLngLat([map.transform.center.lng, map.transform.center.lat]);
      props.updateCoordinates([map.transform.center.lng, map.transform.center.lat]);
    });

    map.on("load", () => {
      map.resize();
    });

    return () => {
      map.remove();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <div
      style={{ position: "relative" }}
      ref={mapContainerRef}
      className="map"
    />
  );
};

export default AddPlaceMap;
