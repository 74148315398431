import { IonChip, IonAvatar, IonLabel } from "@ionic/react";

function CustomChips(props: any) {
  return (
    <IonChip
      style={
        props.selectedCategoryArr.includes(props.id)
          ? selectedStyle
          : unSelectedStyle
      }
      onClick={() => {
        props.selectcategoryFun(props.id);
      }}
    >
      {props.icon ? (
        <IonAvatar>
          <img alt="" src={props.icon} />
        </IonAvatar>
      ) : null}
      <IonLabel>{props.name || props.id} </IonLabel>
    </IonChip>
  );
}
export default CustomChips;

const selectedStyle = {
  background: "black",
  color: "white",
  border: "1px solid white",
};

const unSelectedStyle = {
  background: "white",
  color: "black",
  border: "1px solid black",
};
