import { useState, useEffect, useRef, useContext } from "react";
import { IonContent, IonImg } from "@ionic/react";
import maplibregl from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";
import "./MapLib.css";
import SwiperCards from "../Swiper/SwiperCards";
import CommentsModal from "../modals/CommentsModal";
import { useQuery } from "@apollo/client";
import { getComments, commentsFilter } from "../../utils/apolloQuery";
import SwiperBtns from "../Swiper/SwiperBtns";
import { layersMapArr } from "../../utils/staticVariables";
import authContext from "../../context/authContext";

maplibregl.setRTLTextPlugin(
  "https://unpkg.com/@mapbox/mapbox-gl-rtl-text@0.2.3/mapbox-gl-rtl-text.min.js"
);

export default function Map(props) {
  const [generatedMap, setGeneratedMap] = useState(null);
  const [generatedMarker, setGeneratedMarker] = useState(null);
  const [openedModel, setOpenedModel] = useState(false);
  const [selectedLayer, setSelectedLayer] = useState([layersMapArr[0].id]);
  const [selectedPlace, setSelectedPlace] = useState("");
  const [isMapLoding, setIsMapLoding] = useState(false);
  const [lastSelectedLayerLegend, setLastSelectedLayerLegend] = useState(
    "https://coralreefwatch.noaa.gov/product/vs/legends/trans_border_baa_gm_legend_sm_v2.png"
  );
  const myAuthContext = useContext(authContext);

  //reload comments
  useEffect(() => {
    if (myAuthContext.updateComment) {
      refetch();
    }
  }, [myAuthContext.updateComment]);

  function getLastSelected() {
    // console.log(selectedLayer[selectedLayer.length - 1]);
    const lastSelectedLayerId = selectedLayer[selectedLayer.length - 1];
    const legendUrl = layersMapArr.find((el) => el.id === lastSelectedLayerId);
    setLastSelectedLayerLegend(legendUrl?.legend);
    // console.log(legendUrl?.legend);
  }

  const mapContainerRef = useRef();
  //todo generate map
  useEffect(() => {
    const map = new maplibregl.Map({
      container: mapContainerRef.current,
      style: `https://api.maptiler.com/maps/streets/style.json?key=jPG7PthF5c3gGwBLewgX`,
      center: [37.1061267471479, 22.580573427888993],
      zoom: 4,
      attributionControl: false,
    });

    //! cluster

    // map.addControl(new maplibregl.NavigationControl(), "top-right");
    // Add geolocate control to the map.
    map.addControl(
      new maplibregl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        // When active the map will receive updates to the device's location as it changes.
        trackUserLocation: true,
        // Draw an arrow next to the location dot to indicate which direction the device is heading.
        showUserHeading: true,
      }),
      "bottom-left"
    );

    setGeneratedMap(map);
    map.on("load", () => {
      map.resize();
    });

    return () => {
      map.remove();
    };
  }, [props]);

  //todo add place points
  useEffect(() => {
    if (props?.place && generatedMap?.isStyleLoaded() === true) {
      const newPointArr = props?.place?.map((el) => ({
        type: "Feature",
        properties: {},
        geometry: {
          coordinates: el.location?.coordinates || [0, 0],
          type: "Point",
        },
      }));

      // Add a new source from our GeoJSON data and
      // set the 'cluster' option to true. GL-JS will
      // add the point_count property to your source data.
      generatedMap.addSource("divingPlace", {
        type: "geojson",
        // Point to GeoJSON data. This example visualizes all M1.0+ divingPlace
        // from 12/22/15 to 1/21/16 as logged by USGS' Earthquake hazards program.
        data: {
          type: "FeatureCollection",
          features: newPointArr,
        },

        cluster: true,
        clusterMaxZoom: 14, // Max zoom to cluster points on
        clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
      });

      generatedMap.addLayer({
        id: "clusters",
        type: "circle",
        source: "divingPlace",
        filter: ["has", "point_count"],
        paint: {
          // Use step expressions (https://maplibre.org/maplibre-gl-js-docs/style-spec/#expressions-step)
          // with three steps to implement three types of circles:
          //   * Blue, 20px circles when point count is less than 100
          //   * Yellow, 30px circles when point count is between 100 and 750
          //   * Pink, 40px circles when point count is greater than or equal to 750
          "circle-color": [
            "step",
            ["get", "point_count"],
            "#51bbd6",
            100,
            "#f1f075",
            750,
            "#f28cb1",
          ],
          "circle-radius": [
            "step",
            ["get", "point_count"],
            20,
            100,
            30,
            750,
            40,
          ],
        },
      });

      generatedMap.addLayer({
        id: "cluster-count",
        type: "symbol",
        source: "divingPlace",
        filter: ["has", "point_count"],
        layout: {
          "text-field": "{point_count_abbreviated}",
          "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
          "text-size": 12,
        },
      });

      generatedMap.addLayer({
        id: "unclustered-point",
        type: "circle",
        source: "divingPlace",
        filter: ["!", ["has", "point_count"]],
        paint: {
          "circle-color": "#11b4da",
          "circle-radius": 4,
          "circle-stroke-width": 1,
          "circle-stroke-color": "#fff",
        },
      });

      // inspect a cluster on click
      generatedMap.on("click", "clusters", function (e) {
        var features = generatedMap.queryRenderedFeatures(e.point, {
          layers: ["clusters"],
        });
        var clusterId = features[0].properties.cluster_id;
        generatedMap
          .getSource("divingPlace")
          .getClusterExpansionZoom(clusterId, function (err, zoom) {
            if (err) return;

            generatedMap.easeTo({
              center: features[0].geometry.coordinates,
              zoom: zoom,
            });
          });
      });

      // When a click event occurs on a feature in
      // the unclustered-point layer, open a popup at
      // the location of the feature, with
      // description HTML from its properties.
      generatedMap.on("click", "unclustered-point", function (e) {
        var coordinates = e.features[0].geometry.coordinates.slice();
        var mag = e.features[0].properties.mag;
        var tsunami;

        if (e.features[0].properties.tsunami === 1) {
          tsunami = "yes";
        } else {
          tsunami = "no";
        }

        // Ensure that if the map is zoomed out such that
        // multiple copies of the feature are visible, the
        // popup appears over the copy being pointed to.
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        //? pop up
        //   new maplibregl.Popup()
        //     .setLngLat(coordinates)
        //     // .setHTML("magnitude: " + mag + "<br>Was there a tsunami?: " + tsunami)
        //      .setHTML("" )
        //     .addTo(generatedMap);
      });

      generatedMap.on("mouseenter", "clusters", function () {
        generatedMap.getCanvas().style.cursor = "pointer";
      });
      generatedMap.on("mouseleave", "clusters", function () {
        generatedMap.getCanvas().style.cursor = "";
      });
      addLayerToMap();
    } else {
      setTimeout(() => {
        setIsMapLoding(!isMapLoding);
      }, 100);
    }
  }, [generatedMap, isMapLoding, props?.place]);

  const addMarker = (coordinates) => {
    const marker = new maplibregl.Marker({ color: "#FF0000" })
      .setLngLat(coordinates)
      .addTo(generatedMap);

    setGeneratedMarker(marker);
  };

  const replaceMarker = (coordinates) => {
    if (!generatedMarker) {
      addMarker(coordinates);
    } else {
      generatedMarker.setLngLat(coordinates);
    }

    generatedMap.flyTo({
      // These options control the ending camera position: centered at
      // the target, at zoom level 9, and north up.
      center: coordinates,
      zoom: 16,
      bearing: 0,

      // These options control the flight curve, making it move
      // slowly and zoom out almost completely before starting
      // to pan.
      speed: 2.2, // make the flying slow
      curve: 2, // change the speed at which it zooms out

      // This can be any easing function: it takes a number between
      // 0 and 1 and returns another number between 0 and 1.
      easing: function (t) {
        return t;
      },

      // this animation is considered essential with respect to prefers-reduced-motion
      essential: true,
    });
  };
  const openModelHandle = (toggle) => {
    setOpenedModel(toggle);
  };

  const { data, refetch } = useQuery(getComments, {
    variables: commentsFilter(""),
    onCompleted: () => {
      myAuthContext.changeUpdateCommentHandle(false);
    },
  });

  const ShowCommentsHandel = (id) => {
    setSelectedPlace(id);
    refetch(commentsFilter(id));
  };

  useEffect(() => {
    if (generatedMap?.isStyleLoaded() === true) {
      addLayerToMap();
      getLastSelected();
    }
  }, [selectedLayer]);

  const addLayerToMap = () => {
    layersMapArr.forEach((el) => {
      if (!generatedMap?.getLayer(el.id) && selectedLayer.includes(el.id)) {
        generatedMap.addSource(el.id, {
          type: "raster",
          tiles: [el.link],
        });

        generatedMap.addLayer({
          id: el.id,
          source: el.id,
          type: "raster",
        });
      } else if (
        generatedMap.getLayer(el.id) &&
        !selectedLayer.includes(el.id)
      ) {
        generatedMap.removeLayer(el.id);
        generatedMap.removeSource(el.id);
      }
    });
  };

  const addSelectedLayerHandle = (layers) => {
    setSelectedLayer(layers);
  };
  return (
    <>
      <IonContent className="map-wrap">
        <IonContent ref={mapContainerRef} className="map" />
        <SwiperBtns btns={layersMapArr} handleChange={addSelectedLayerHandle} />
        <div className="legendImg">
          <IonImg src={lastSelectedLayerLegend || ""}></IonImg>
        </div>
        <SwiperCards
          openModel={openModelHandle}
          funFly={replaceMarker}
          cards={props?.place}
          ShowComments={ShowCommentsHandel}
        />
        <CommentsModal
          comments={data?.comments}
          isOpen={openedModel}
          openModel={openModelHandle}
          placeId={selectedPlace}
        />
      </IonContent>
    </>
  );
}
