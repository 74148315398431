import { Redirect, Route } from "react-router-dom";
import { IonApp, IonRouterOutlet, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import Map from "./pages/Map";
import LogIn from "./pages/LogIn";
import SignUp from "./pages/SignUp";
import AppPlace from "./pages/AppPlace";
import { useState } from "react";
import AuthContext from "./context/authContext";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";

setupIonicReact();

const App: React.FC = () => {
  const [IsLogIn, setIsLogin] = useState(false);
  const [updateComment, setUpdateComment] = useState(false);

  const changeLogInHandle = (toggle: boolean) => {
    setIsLogin(toggle);
  };

  const changeUpdateCommentHandle = (toggle: boolean) => {
    setUpdateComment(toggle);
  };

  return (
    <AuthContext.Provider
      value={{
        isLogIn: IsLogIn,
        changeLogInHandle: changeLogInHandle,
        updateComment: updateComment,
        changeUpdateCommentHandle :changeUpdateCommentHandle
      }}
    >
      <IonApp style={{ maxWidth: "450px", margin: "auto" }}>
        <IonReactRouter>
          <IonRouterOutlet>
            <Route exact path="/map">
              <Map />
            </Route>
            <Route exact path="/sign-up">
              <SignUp />
            </Route>
            <Route path="/log-in">
              <LogIn />
            </Route>
            <Route path="/add-place">
              <AppPlace />
            </Route>
            <Route exact path="/">
              <Redirect to="/map" />
            </Route>
          </IonRouterOutlet>
        </IonReactRouter>
      </IonApp>
    </AuthContext.Provider>
  );
};

export default App;
