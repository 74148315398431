import {
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
} from "@ionic/react";
import { getAssetURL } from "../../utils/get-asset-url";
import "./CustomSliderCard.css";

function CustomSliderCard(props: any) {
  return (
    <IonCard
      className="place-card"
      onClick={() => {
        props.openModel(true);
        props.ShowComments(props.id);
      }}
    >
      <img
        alt="Silhouette of mountains"
        src={
          getAssetURL(props?.main_image?.id) ||
          "https://ionicframework.com/docs/img/demos/card-media.png"
        }
      />

      <IonCardHeader>
        <IonCardTitle>{props?.name || "Unknown Place"}</IonCardTitle>
        <IonCardSubtitle>
          {props?.r_levelone_id?.name || "Unknown Region"}
        </IonCardSubtitle>
      </IonCardHeader>
    </IonCard>
  );
}
export default CustomSliderCard;
